<template>
  <div>
    <welcome-message-text class='mt-12 px-2 lg:px-0'/>
    <president-image class='mt-12 px-2 lg:px-0 w-full lg:w-96' />
  </div>
</template>

<script>
import WelcomeMessageText  from '@/components/welcome/WelcomeMessageText.vue'
import PresidentImage      from '@/components/welcome/PresidentImage.vue'

export default {
  name: 'Welcome',
  components: {
    WelcomeMessageText,
    PresidentImage,
  },
}
</script>
